<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <!-- swiper -->
    <div class="container no-banner">
      <br>
      <vx-card title="Négociateur" v-if="negociateur !== '' && negociateur !== undefined">
        <br>
        <vs-table :data="negociateur">
          <template slot-scope="{data}">
            <vs-tr>
              <vs-td class="headTab">
                Trigramme
              </vs-td>
              <vs-td :data="data[0].trigramme">
                {{data[0].trigramme}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Email
              </vs-td>
              <vs-td :data="data[0].email">
                {{data[0].email }}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nom
              </vs-td>
              <vs-td :data="data[0].lastName">
                {{data[0].lastName }}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Prénom
              </vs-td>
              <vs-td :data="data[0].firstName">
                {{data[0].firstName}}
              </vs-td>
            </vs-tr>

            <vs-tr>
              <vs-td class="headTab">
                Télephone
              </vs-td>
              <vs-td :data="data[0].telephone">
                {{data[0].telephone}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de commandes en cours
              </vs-td>
              <vs-td :data="nbCommandesAttente">
                <span class="nbCommandes Attente">{{nbCommandesAttente}}</span>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de commandes clôturées
              </vs-td>
              <vs-td :data="nbCommandesCloturees">
                <span class="nbCommandes Clotures">{{nbCommandesCloturees}}</span>
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>
        <br>
        <div class="text-right" style="margin-bottom: 30px">
          <vs-button @click="showAgenda()" class="mr-3 mb-2 float-right" icon="icon-calendar" icon-after
                     icon-pack="feather">Agenda du négociateur
          </vs-button>
        </div>
        <br>
      </vx-card>
      <vx-card action-buttons @refresh="closeCardAnimationDemo" title="Commande" class="mt-10">
        <vs-table :data="commandes" v-if="commandes !== '' && commandes !== undefined">
          <template slot-scope="{data}">
            <vs-tr>
              <vs-td class="headTab">
                Statut
              </vs-td>
              <vs-td>
                <select class="vs-select w-6/12" v-model="data[0].statut" v-if="data[0].statut != 'EA'">
                  <option v-for="item in optionsStatut" :key="item" :value="item">{{item | statutConvert}}</option>
                </select>
                <div :data="data[0].statut" class="status w-5/12" >
                  <span :class="data[0].statut">{{data[0].statut | statutConvert}}</span>
                </div>

              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Réference
              </vs-td>
              <vs-td :data="data[0].reference">
                {{data[0].reference}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date de création
              </vs-td>
              <vs-td :data="data[0].dateCreation">
                {{dateCreation | formDate}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date de prise en charge
              </vs-td>
              <vs-td :data="data[0].datePriseCharge">
                {{datePriseCharge | formDate}}

              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date de clôture
              </vs-td>
              <vs-td :data="data[0].dateCloture">
                {{dateCloture | formDate}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Formule
              </vs-td>
              <vs-td :data="data[0].formule">
              <select class="vs-select w-full" v-model="data[0].formule">
                <option v-for="item in optionsFormule" :key="item.id" :value="item.libelle">{{item.libelle }}</option>
              </select>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].offreCommande !== undefined">
              <vs-td class="headTab">
                Promotion
              </vs-td>
              <vs-td :data="data[0].offreCommande">
                <vs-td :data="data[0].offreCommande.libelle">
                  {{data[0].offreCommande.libelle}}
                </vs-td>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Critères
              </vs-td>
              <vs-td  >
                <vs-checkbox vs-value="Maison" required v-model="data[0].criteres">Maison</vs-checkbox>
                <vs-checkbox vs-value="Terrain" required="'required'" v-model="data[0].criteres">Terrain</vs-checkbox>
                <vs-checkbox vs-value="Appartement" required="'required'" v-model="data[0].criteres">Appartement</vs-checkbox>
                <vs-checkbox vs-value="Loft" required="'required'" v-model="data[0].criteres">Loft</vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Secteur
              </vs-td>
              <vs-td :data="secteur">

                <div v-for="(item, index) in arrayCodesPostaux" :key="index">
                  <div v-if="item.value !== null">
                  {{ item.value.codePostal }} {{ item.value.name }} ({{ item.value.population }} Habitants) <feather-icon @click="deleteElement(index)" svgClasses="w-4 h-4" icon="XCircleIcon" class="mr-2 cursor-pointer"/>
                  </div>
                </div><br>
                <vue-tags-input
                  class="form-control vs-input vx-col w-full"
                  :class="erreurs.postalCodes"
                  v-model="tag"
                  :sort-search-results="true"
                  :tags="tags"
                  :autocomplete-items="autocompleteItems"
                  avoid-adding-duplicates
                  :add-only-from-autocomplete="true"
                  @tags-changed="update"
                  @before-adding-tag="add"
                  placeholder="Ajouter un code postal"
                  required="'required'">
                </vue-tags-input>

                <br>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Estimation d'annonces
              </vs-td>
              <vs-td>
                {{data[0].estimatedAds}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Prestataire
              </vs-td>
              <vs-td>
                <select class="vs-select w-full" v-model="data[0].prestataire">
                  <option v-for="item in optionsPrestataire" :key="item.id" :value="item.name">{{item.name }}</option>
                </select>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Téleconseiller
              </vs-td>
              <vs-td>
                  <select class="vs-select w-full" v-model="employee">
                    <option v-for="item in optionsTeleconseiller" :key="item.value" :value="item.value">{{item.label }}</option>
                  </select>

              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <vx-card action-buttons @refresh="closeCardAnimationDemo" title="Rendez-vous" class="mt-10">

        <vx-card action-buttons @refresh="closeCardAnimationDemo" class="mb-10">
        <vs-table :data="RendezVous" v-if="RendezVous !== ''">

          <template slot="thead">

            <vs-th>Id</vs-th>
            <vs-th>Date début</vs-th>
            <vs-th>Mandat</vs-th>
            <vs-th>Statut</vs-th>
            <vs-th>Lien</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :state="data[indextr].statut == 'Réalisé' ? 'success': data[indextr].statut == 'Annulé' ? 'warning':null" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].dateDebut">
                {{ data[indextr].dateDebut }}
              </vs-td>
              <vs-td :data="data[indextr].mandat">
                {{ data[indextr].mandat }}
              </vs-td>
              <vs-td :data="data[indextr].statut">
                {{ data[indextr].statut }}
              </vs-td>
              <vs-td :data="data[indextr].id">
                <vs-button
                  color="primary"
                  type="filled"
                  :to="{
                    name: 'admin-appointments-id',
                    params: { id: data[indextr].id }
                  }"
                >
                  Voir
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
        </vx-card>
        <vs-table :data="commandes" v-if="commandes !== '' && commandes !== undefined">
          <template slot-scope="{data}">
            <vs-tr>
              <vs-td class="headTab">
                Date du dérnier rendez-vous pris
              </vs-td>
              <vs-td :data="data[0].dateDernierRdv" v-if="data[0].dernierRdv != undefined">
                <flat-pickr :config="configdateTimePicker" placeholder="Date du dérnier rendez-vous"
                            v-model="data[0].dernierRdv.dateDebut" :disabled="admin"/>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous
              </vs-td>
              <vs-td :data="data[0].nbRdv">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdv" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous pris
              </vs-td>
              <vs-td :data="data[0].nbRdvPris">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdvPris" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous recrédités + commandés
              </vs-td>
              <vs-td :data="data[0].nbRdsRecreditesCommandes">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdsRecreditesCommandes" :disabled="admin"
                />
              </vs-td>
            </vs-tr>

            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous réalisés
              </vs-td>
              <vs-td :data="data[0].nbRdsRealises">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdsRealises" :disabled="admin"
                />
              </vs-td>
            </vs-tr>

            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous annulés
              </vs-td>
              <vs-td :data="data[0].annulation">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].annulation" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous Restants
              </vs-td>
              <vs-td :data="data[0].nbRdvRestants">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdvRestants" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous Recredités
              </vs-td>
              <vs-td :data="data[0].nbRdsRecredites">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].nbRdsRecredites" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre ventes réalisés
              </vs-td>
              <vs-td :data="data[0].ventes">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].ventes" :disabled="admin"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de mandats réalisés
              </vs-td>
              <vs-td :data="data[0].mandats">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].mandats" :disabled="admin"
                />
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>
      </vx-card>
      <vx-card action-buttons @refresh="closeCardAnimationDemo" title="Informations supplémentaires" class="mt-10">
        <vs-table :data="commandes" v-if="commandes !== '' && commandes !== undefined">
          <template slot-scope="{data}">
            <vs-tr>
              <vs-td class="headTab">
                Résiliation
              </vs-td>
              <vs-td :data="data[0].resilie">
                <vs-input
                  class="w-full mt-6"
                  type="text"
                  v-model="data[0].resilie"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date de résiliation
              </vs-td>
              <vs-td :data="data[0].dateResiliation">
                <flat-pickr :config="configdateTimePicker" placeholder="Date de résiliation"
                            v-model="data[0].dateResiliation"/>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date mail
              </vs-td>
              <vs-td :data="data[0].dateMail">
                <flat-pickr :config="configdateTimePicker" placeholder="Date Mail" v-model="data[0].dateMail"/>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Réponse Mail
              </vs-td>
              <vs-td :data="data[0].reponseMail">
                <textarea
                  class="w-full mt-6 textarea"
                  type="text"
                  v-model="data[0].reponseMail"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Numéros à exclure
              </vs-td>
              <vs-td :data="data[0].numeros">
                <textarea
                  class="w-full mt-6 textarea"
                  type="text"
                  v-model="data[0].numeros"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Commentaire
              </vs-td>
              <vs-td :data="data[0].commentaire">
                <textarea
                  class="w-full mt-6 textarea"
                  type="text"
                  v-model="data[0].commentaire"
                />
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>
        <br>
        <div class="text-right">
          <vs-button @click="popupActive=true" class="mr-3 mb-2" v-if="showValider">Valider</vs-button>
          <vs-button @click="Update()" class="mr-3 mb-2" type="border">Mettre à jour</vs-button>
        </div>
      </vx-card>
      <vx-card title="Historique de la commande" class="mt-10" action-buttons @refresh="closeCardAnimationDemo" >
          <vx-timeline :data="timelineData" />
        </vx-card>

      <vs-popup :active.sync="popupActive" v-if="commandes !== '' && commandes !== undefined"
                class="grande" title="Validation de la commande">
        <h4>Réference : {{commandes[0].reference}}</h4><br><br>
        <br>
        <b>Préstataire</b> :
        <select class="vs-select w-full" v-model="prestataireNew">
          <option v-for="item in optionsPrestataire" :key="item.id" :value="item">{{item.name }}</option>
        </select>
        <br>
        <div v-if="prestataireNew.name == 'PP'">
          <b>Téleconseiller</b> :
          <v-select :options="optionsTeleconseiller" placeholder="Téleconseiller" v-model="teleconseillerNew"/>
          <br>
        </div>
        <vs-divider> Voulez-vous refuser cette commande?</vs-divider>
        <vs-checkbox v-model="refuser">Refuser</vs-checkbox>
        <br>
        <vs-textarea label="Motif de refus" v-model="motif"/>
        <vs-button @click="Envoyer()" class="mr-3 mb-2 float-right" v-if="showValider">Valider</vs-button>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import axios from '@/axios.js';
import { colors } from '@/../themeConfig';
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VxTimeline from '@/components/timeline/VxTimeline';
import VueTagsInput from '@johmun/vue-tags-input';
import Autocomplete from 'vuejs-auto-complete';
import Textarea from '@/views/forms/form-elements/textarea/Textarea';
import router from '@/router';

export default {
  components: {
    VueTagsInput,
    Textarea,
    Datepicker,
    swiper,
    swiperSlide,
    'v-select': vSelect,
    flatPickr,
    VxTimeline,
    Autocomplete,
  },
  props: ['id'],
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    statutConvert(value) {
      switch (value) {
        case 'EP':
          return 'En attente de paiement';
        case 'EA':
          return 'En attente';
        case 'SB':
          return 'Suspendue';
        case 'EC':
          return 'En cours';
        case 'CLO':
          return 'Cloturée';
        case 'NR':
          return 'Non renseigné';
        case 'RF':
          return 'Refusée';
        case 'CB':
          return 'Crédit boutique';
        default:
          return 'En cours';
      }
    },
  },
  data() {
    return {
      employee: '',
      newCode: '',
      arrayCodesPostaux: [],
      autocompleteItems: [],
      tags: [],
      tag: '',
      nbRdvCommande: 0,
      erreurs: {
        postalCodes: '',
      },
      timelineData: [],
      // Area charts
      format: 'dd/MM/yyyy HH:mm:ss',
      configdateTimePicker: {
        dateFormat: 'Y-m-d H:i',
        defaultDate: null,
        timeZoneType: '3',
      },
      secteur: [],
      popupActive: false,
      showValider: false,
      dataNego: '',
      enAttente: '',
      colors,
      Suspendus: '',
      enCours: '',
      Clotures: '',
      commandes: [],
      negociateur: '',
      titreCommande: '',
      nbCommandesAttente: 0,
      nbCommandesCloturees: 0,
      optionsStatut: ['EC', 'SB', 'CLO', 'NR', 'CB'],
      optionsFormule: [],
      optionsTeleconseiller: '',
      refuser: false,
      prestataireNew: '',
      teleconseillerNew: '',
      motif: '',
      historique: '',
      oldStatut: '',
      optionsPrestataire: [],
      RendezVous: '',
      admin: true,

    };
  },
  computed: {
    criteres() {
      return this.commandes[0].criteres;
    },
    authHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };
    },
    datePriseCharge() {
      return this.commandes[0].datePriseCharge;
    },
    statut() {
      return this.commandes[0].statut;
    },
    dateCloture() {
      return this.commandes[0].dateCloture;
    },
    dateCreation() {
      return this.commandes[0].dateCreation;
    },
    dateDernierRdv() {
      if (this.commandes[0].dateDernierRdv == '') return null;
      return this.commandes[0].dateDernierRdv;
    },
    mandats() {
      return this.commandes[0].mandats;
    },
    ventes() {
      return this.commandes[0].ventes;
    },
    nbRdsRecredites() {
      return this.commandes[0].nbRdsRecredites;
    },
    nbRdvPris() {
      return this.commandes[0].nbRdvPris;
    },

    nbRdvRestants() {
      return this.commandes[0].nbRdvRestants;
    },
    formule() {
      return this.commandes[0].formule;
    },
    fonctionnement() {
      return this.commandes[0].fonctionnement;
    },
    prestataire() {
      return this.commandes[0].prestataire;
    },
    nbRdv() {
      return this.commandes[0].nbRdv;
    },
    nbRdsRealises() {
      return this.commandes[0].nbRdsRealises;
    },
    nbRdsRecreditesCommandes() {
      return this.commandes[0].nbRdsRecreditesCommandes;
    },
    annulation() {
      return this.commandes[0].annulation;
    },
    promotion() {
      return this.commandes[0].promotion;
    },
    commentaire() {
      return this.commandes[0].commentaire;
    },
    dateResiliation() {
      if (this.commandes[0].dateResiliation == '') return null;
      return this.commandes[0].dateResiliation;
    },
    resilie() {
      return this.commandes[0].resilie;
    },
    date() {
      return this.commandes[0].dateResiliation;
    },
    dateMail() {
      return this.commandes[0].dateMail;
    },
    reponseMail() {
      return this.commandes[0].reponseMail;
    },
    teleconseiller() {
      if (this.commandes[0].employee) {
        return `${this.commandes[0].employee.firstName} ${this.commandes[0].employee.lastName}`;
      }
    },
  },
  watch: {
    tag: 'initItems',
  },
  methods: {
    getFormules() {
      axios.get('/api/formule_commandes', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.optionsFormule = response.data;
        }
      })
        .catch(function (error) {
          this.$toasted.show(error).goAway(1500);
        });
    },
    closeCardAnimationDemo(card) {
      card.removeRefreshAnimation(3000);
    },
    endPoint(input) {
      return `${process.env.VUE_APP_BASE_URL}/api/cities?codePostal=${input}`;
    },
    formattedDisplay(resultat) {
      return `${resultat.codePostal} ${resultat.name} (${resultat.population} habitants)`;
    },
    deleteElement(index) {
      this.arrayCodesPostaux.splice(index, 1);
    },
    add(obj) {
      if (obj.tag.code !== undefined) {
        this.arrayCodesPostaux.push({ value: obj.tag });
      }
    },
    update(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },
    initItems() {
      if (this.tag.length === 0) {
        this.activeLoading = false;
        this.$vs.loading.close();
        return;
      }
      this.activeLoading = true;
      this.$vs.loading({
        type: this.typeLoad,
      });
      const url = `/api/cities?codePostal=${this.tag}`;

      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then((response) => {
          this.autocompleteItems = response.data.map((a) =>
          // return result.name;
            ({
              text: `${a.codePostal} ${a.name} (${a.population} habitants)`,
              id: a.id,
              name: a.name,
              codePostal: a.codePostal,
              population: a.population,
              code: a.code,
              nbAnnonces: a.nbAnnonces,
            }));
          this.activeLoading = false;
          this.$vs.loading.close();
        });
      }, 600);
    },
    showAgenda() {
      router.push({ name: 'admin-diary', query: { idNego: this.negociateur[0].id } });
    },
    Envoyer() {
      const date = moment().format('MM/DD/YYYY  h:mm:ss a');
      let data = {};
      let dataPromotion = {};
      let employee = null;

      // Refuser une commande
      if (this.refuser) {
        data = {
          statut: 'RF',
          commentaire: this.motif,
        };
      } else {
        if (this.prestataireNew.name == 'PP') {
          employee = `/api/employees/${this.teleconseillerNew.value}`;
        } else if (this.prestataireNew.name == 'UPINTERACTIONS') {
          // Souhir Bouhadi
          employee = '/api/employees/2950';
        }
        if (this.formule == 'Commande Premium') {
          data = {
            statut: 'EC',
            prestataire: this.prestataireNew.name,
            employee,
            datePriseCharge: date,
            oldStatut: this.oldStatut,
          };
        } else {
          data = {
            statut: 'EC',
            prestataire: this.prestataireNew.name,
            employee,
            datePriseCharge: date,
            oldStatut: this.oldStatut,
          };
          if (this.formule == 'Commande Debutant') {
            // Dans le cas des offres débutant, on doit enregistrer la date de commande
            dataPromotion = {
              dateCommande: date,
            };
            axios.put(`/api/offre_debutants/${this.promotion}`, dataPromotion, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            }).then((Response) => Response);
          }
        }
      }
      axios.put(`api/commandes/${this.id}`, data, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.status == 200) {
          this.$vs.notify({
            title: 'Succès',
            text: 'La commande a été mise à jour avec succès',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 5000,
          });
        }

        this.popupActive = false;
        this.getHistorique();
        this.getCommande();
      }).catch((error) => {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 4000,
        });
      });
    },
    Update() {
      let communes = '';
      this.arrayCodesPostaux.forEach((element) => {
        communes += `/ ${element.value.code}-${element.value.codePostal}`;
      });

      let data = {};
      let employee = null;
      if (this.employee != '') {
        employee = `/api/employees/${this.employee}`;
      }
      data = {
        mandats: parseInt(this.mandats),
        ventes: parseInt(this.ventes),
        annulation: parseInt(this.annulation),
        nbRdv: parseInt(this.nbRdv),
        nbRdsRealises: parseInt(this.nbRdsRealises),
        nbRdvPris: parseInt(this.nbRdvPris),
        nbRdsRecredites: parseInt(this.nbRdsRecredites),
        nbRdsRecreditesCommandes: parseInt(this.nbRdsRecreditesCommandes),
        nbRdvRestants: parseInt(this.nbRdvRestants),
        dateDernierRdv: this.dateDernierRdv,
        statut: this.statut,
        oldStatut: this.oldStatut,
        formule: this.formule,
        commentaire: this.commentaire,
        resilie: this.resilie,
        dateResiliation: this.dateResiliation,
        dateMail: this.dateMail,
        reponseMail: this.reponseMail,
        criteres: this.commandes[0].criteres,
        numeros: this.commandes[0].numeros,
        codesInsee: communes,
        prestataire: this.commandes[0].prestataire,
        employee,
      };
      if (this.statut == 'CB') {
        if (this.nbRdvCommande > 0) {
          this.$vs.notify({
            title: 'Erreur',
            text: `Crédit boutique impossible, il reste ${this.nbRdvCommande} rendez-vous à régler.`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            time: 5000,
          });
          return;
        }
      }
      if (communes !== '') {
        data.secteur = null;
      }
      this.activeLoading = true;
      this.$vs.loading({
        type: this.typeLoad,
      });
      axios.put(`api/commandes/${this.id}`, data, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        this.activeLoading = false;
        this.$vs.loading.close();
        if (response.status == 200) {
          this.$vs.notify({
            title: 'Succès',
            text: 'La commande a été mise à jour avec succès',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 5000,
          });
        }
        this.getHistorique();
        this.getCommande();
      }).catch((error) => {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 4000,
        });
      });
    },
    getHistorique() {
      const colors = ['primary', 'warning', 'danger', 'success', 'blue', 'yellow'];
      // Historique des actions
      axios.get(`/api/historique_actions?commande=${this.id}&order[dateAction]=desc`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          let oneLine = {};
          const timeline = [];
          const { data } = response;
          let i = (response.data).length;
          data.forEach((value) => {
            oneLine = {
              color: colors[Math.floor(Math.random() * colors.length)],
              icon: value.libelle == 'Validation' ? 'CheckCircleIcon' : 'EditIcon',
              title: value.libelle,
              desc: `${value.text}\n \nPar ${value.who}`,
              time: value.dateAction,
            };
            if (value.libelle == 'Mise à jour') {
              oneLine.title = `${value.libelle} ${i}`;
              i--;
            }

            timeline.push(oneLine);
          });

          this.timelineData = timeline;
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    getRdvs() {
      axios.get(`/api/rendez_vouses?commande=${this.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          let nbRdv = 0;
          response.data.forEach((value) => {
            if (value.statut == 'A venir' || value.statut == 'A statuer') {
              nbRdv += 1;
            }
          });
          this.nbRdvCommande = nbRdv;
          this.RendezVous = response.data;
          // console.log("this"+ this.nbRdvCommande)
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    getCommande() {
      // Les commandes
      let arraySecteur = '';
      const regexNumbers = new RegExp('^[0-9]+$');
      axios.get(`/api/commandes/${this.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((firstResponse) => {
          const arrayCodesPostaux = [];
          this.commandes = [firstResponse.data];
          if (firstResponse.data.employee) {
            this.employee = firstResponse.data.employee.id;
          } else {
            this.employee = '';
          }
          if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN') && firstResponse.data.statut == 'EA') {
            this.showValider = true;
          }
          this.oldStatut = firstResponse.data.statut;
          // Si ce sont des anciennes commandes importées part le fichier excel donc elles contiennent le champs secteur
          if (firstResponse.data.codesInsee) {
            // Si ce sont des nouvelles commandes
            const arrayCodes = (firstResponse.data.codesInsee).split('/');
            for (const q in arrayCodes) {
              if ((arrayCodes[q]).trim().length > 0) {
                const code = arrayCodes[q].split('-');
                axios.get(`/api/cities?code=${code[0].trim()}&codePostal=${code[1].trim()}`, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                  },
                }).then((response) => {
                  if (response.data) {
                    arraySecteur += `\n${response.data[0].name} ${response.data[0].codePostal} , ${response.data[0].population} habitants`;
                    this.secteur = arraySecteur;
                    arrayCodesPostaux.push({ value: response.data[0] });
                  }
                });
              }
            }
            this.arrayCodesPostaux = arrayCodesPostaux;
          } else {
            const arrayCodes = (firstResponse.data.secteur||'').split('/');
            for (const prop in arrayCodes) {
              const city = arrayCodes[prop].trim();
              if (city.length > 0) {
                // Si c'est un code postal
                if (city.match(regexNumbers)) {
                  axios.get(`/api/cities?codePostal=${city}`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  }).then((secondResponse) => {
                    if (secondResponse.data.length > 0) {
                      secondResponse.data.forEach((value) => {
                        arraySecteur += `\n${value.name} ${value.codePostal} , ${value.population} habitants`;
                        arrayCodesPostaux.push({ value });
                      });
                    } else {
                      arraySecteur += `\n${city}`;
                    }
                  });
                } else {
                  let codePostal = city;
                  codePostal = codePostal.replace(/\D+/g, '');
                  let url = `/api/cities?name=${city.replace(/[0-9]+/, '').replace(/^\s/, '')}`;
                  if (codePostal.length > 0) {
                    url = `${url.replace(/\s$/, '')}&codePostal=${codePostal}`;
                  }
                  axios.get(url, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  }).then((response) => {
                    if (response.data.length > 0) {
                      response.data.forEach((value) => {
                        arraySecteur += `\n${value.name} ${value.codePostal} , ${value.population} habitants`;
                        arrayCodesPostaux.push({ value });
                      });
                    } else {
                      arraySecteur += `\n${city}`;
                    }
                  });
                }
              }
            }
            this.arrayCodesPostaux = arrayCodesPostaux;
          }
          axios.get(`/api/mandataries?trigramme=${firstResponse.data.mandatary.trigramme}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          })
            .then((secondResponse) => {
              this.negociateur = secondResponse.data;
              axios.get(`/api/commandes?statut=EC&trigramme=${firstResponse.data.trigramme}`, {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
              })
                .then((thirdResponse) => {
                  this.nbCommandesAttente = thirdResponse.data.length;
                  axios.get(`/api/commandes?statut=CLO&trigramme=${firstResponse.data.trigramme}`, {
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  })
                    .then((fourthResponse) => {
                      this.nbCommandesCloturees = fourthResponse.data.length;
                    });
                });
            });
        })
        .catch((error) => {
          if (error.response !== null) {
            if (error.response) {
              this.$vs.notify({
                title: 'Erreur',
                text: "Vous n'êtes pas autorisé à accéder à cette commande",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                time: 4000,
              });
              router.push({ name: 'admin-orders' });
            }
          } else {
            this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
          }
        });
    },
    getPrestataires() {
      // Historique des actions
      axios.get('/api/prestataires', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.optionsPrestataire = response.data;
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
  },
  created() {
    if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN')) {
      this.admin = false;
    }
    this.getPrestataires();
    // Liste des téleconseillers
    axios.get('/api/employees?serviceId=11&enabled=true&properties[]=id&properties[]=firstName&properties[]=lastName&pagination=false', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        const arrayTeleconseillers = [{ label: null, value: '' }];
        response.data.forEach((key) => {
          arrayTeleconseillers.push({ label: `${key.firstName} ${key.lastName}`, value: key.id });
        });
        this.optionsTeleconseiller = arrayTeleconseillers;
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });

    this.getCommande();
    this.getRdvs();
    this.getHistorique();
    this.getFormules();
  },
};
</script>
<style>
  .ti-autocomplete{
    position: relative !important;
  }
  .vs-con-table .vs-con-tbody .con-vs-checkbox {
    justify-content: flex-start !important;
  }
  .status {
    width: 50%;
    margin-right: 10px;
    float: left;
    vertical-align: middle;
    margin-top: 10px;
  }
</style>
